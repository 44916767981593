/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `ChevronRightProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ChevronRightProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'ChevronRight'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `ChevronRight` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ChevronRightProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the ChevronRightIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the ChevronRightIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the ChevronRightIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ChevronRightComponent = <ChevronRight className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const ChevronRight = React.forwardRef<SVGSVGElement, ChevronRightProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 24,
        testId = 'ChevronRight',
        width = 24
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            style={{transform: 'rotate(90deg)'}}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M19.53 16.53c-.3.3-.77.3-1.06 0L12 10.06l-6.47 6.47a.75.75 0 0 1-1.06-1.06l7-7c.3-.3.77-.3 1.06 0l7 7c.3.3.3.77 0 1.06Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

ChevronRight.displayName = 'ChevronRight';