/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `NewTextProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface NewTextProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#192630'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 27
     */
    height?: number | string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#E5EAEE'
     */
    secondaryColor?: string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'NewText'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 30
     */
    width?: number | string;
}

/**
 * The `NewText` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `NewTextProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props                The component props.
 * @param props.className      A string representing the CSS class to be applied to the NewTextIcon element to override its default styling.
 * @param props.color1         A string representing the color to be applied to the Icon.
 * @param props.secondaryColor A string representing the color to be applied to the Icon.
 * @param props.height         A number or string representing the height of the NewTextIcon element.
 * @param props.testId         A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width          A number or string representing the height of the NewTextIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const NewTextComponent = <NewText className="myClass" color1="#000" secondaryColor="#FFF" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const NewText = React.forwardRef<SVGSVGElement, NewTextProps>((props, ref) => {
    const {
        className = '',
        color1 = '#192630',
        height = 27,
        secondaryColor = '#E5EAEE',
        testId = 'NewText',
        width = 30
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 30 27"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect fill="transparent" height="19.5" rx="1.25" stroke={color1} strokeWidth="1.5" width="18.5" x="5.75" y="3.75" />
            <path d="M15.86 18h-1.37V9.43H11.4v-.56c0-.32.18-.5.5-.5h7.03v1.06h-3.08V18Z" fill={color1} />
            <circle cx="6" cy="21" fill={secondaryColor} r="5" />
            <path clipRule="evenodd" d="M6 16.38a4.63 4.63 0 1 0 0 9.25 4.63 4.63 0 0 0 0-9.25ZM.62 21a5.38 5.38 0 1 1 10.76 0A5.38 5.38 0 0 1 .62 21ZM6 18.62c.2 0 .38.17.38.38v1.63H8a.38.38 0 0 1 0 .75H6.37V23a.38.38 0 0 1-.75 0v-1.63H4a.38.38 0 0 1 0-.75h1.63V19c0-.2.16-.38.37-.38Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

NewText.displayName = 'NewText';