/* eslint-disable max-len */
import React from 'react';

import styled from 'styled-components';

/**
 * The `ShapeColorProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ShapeColorProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0090D0'
     */
    color1?: string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'ShapeColor'
     */
    testId?: string;
}

/**
 * The `ShapeColor` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ShapeColorProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the ShapeColorIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ShapeColorComponent = <ShapeColor className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const ShapeColor = React.forwardRef<SVGSVGElement, ShapeColorProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0090D0',
        testId = 'ShapeColor'
    } = props;

    return (
        <Gradient>
            <svg
                ref={ref}
                className={className}
                data-cy={testId}
                height="100%"
                viewBox="0 0 18 18"
                width="100%"
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
            >
                <circle cx="9" cy="9" fill={color1} r="7" />
            </svg>
        </Gradient>
    );
});

ShapeColor.displayName = 'ShapeColor';

const Gradient = styled.div`
    align-items: center;
    aspect-ratio: 1;
    display: flex;
    font-size: 0;
    justify-content: center;
    line-height: 0;
    padding: 2px;
    position: relative;
    width: 100%;

    &::before {
        -webkit-mask-composite: xor;
        background: conic-gradient(from 0.50turn at 50% 50%, #000066, #0019A5, #0090D0, #41B6E6, #B1E4E3, #FFC548, #F65D7C, #824CAB, #74CF9A, #39C7C5) border-box;
        border: 1.5px solid transparent;
        border-radius: 50%;
        content: '';
        height: 100%;
        mask:
            linear-gradient(#fff 0 0) padding-box,
            linear-gradient(#fff 0 0);
        mask-composite: exclude;
        position: absolute;
        width: 100%;
    }
`;