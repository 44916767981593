/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `RectangleProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface RectangleProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#192630'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 16
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Rectangle'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 16
     */
    width?: number | string;
}

/**
 * The `Rectangle` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `RectangleProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the RectangleIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the RectangleIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the RectangleIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const RectangleComponent = <Rectangle className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Rectangle = React.forwardRef<SVGSVGElement, RectangleProps>((props, ref) => {
    const {
        className = '',
        color1 = '#192630',
        height = 16,
        testId = 'Rectangle',
        width = 16
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 16 16"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <rect fill="transparent" height="16" rx="1" stroke={color1} strokeWidth="3" width="16" />
        </svg>
    );
});

Rectangle.displayName = 'Rectangle';