/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `EditSmallProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface EditSmallProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'EditSmall'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `EditSmall` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `EditSmallProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the EditSmallIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the EditSmallIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the EditSmallIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const EditSmallComponent = <EditSmall className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const EditSmall = React.forwardRef<SVGSVGElement, EditSmallProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 24,
        testId = 'EditSmall',
        width = 24
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M19.03 3.03a1.37 1.37 0 0 1 1.94 1.94l-9.35 9.35-2.59.65.65-2.59 9.35-9.35Zm.97-1.9c-.76 0-1.5.3-2.03.84l-9.5 9.5c-.1.1-.16.22-.2.35l-1 4a.75.75 0 0 0 .91.9l4-1a.8.8 0 0 0 .35-.19l9.5-9.5A2.87 2.87 0 0 0 20 1.13ZM4 3.25A2.75 2.75 0 0 0 1.25 6v14A2.75 2.75 0 0 0 4 22.75h14A2.75 2.75 0 0 0 20.75 20v-7a.75.75 0 0 0-1.5 0v7A1.25 1.25 0 0 1 18 21.25H4A1.25 1.25 0 0 1 2.75 20V6A1.25 1.25 0 0 1 4 4.75h7a.75.75 0 0 0 0-1.5H4Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

EditSmall.displayName = 'EditSmall';