/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `SaveProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface SaveProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Save'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `Save` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `SaveProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the SaveIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the SaveIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the SaveIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const SaveComponent = <Save className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Save = React.forwardRef<SVGSVGElement, SaveProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 24,
        testId = 'Save',
        width = 24
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M5 3.75A1.25 1.25 0 0 0 3.75 5v14A1.25 1.25 0 0 0 5 20.25h1.25V13c0-.41.34-.75.75-.75h10c.41 0 .75.34.75.75v7.25H19A1.25 1.25 0 0 0 20.25 19V8.31l-4.56-4.56H7.75v3.5H15a.75.75 0 0 1 0 1.5H7A.75.75 0 0 1 6.25 8V3.75H5Zm2-1.5H5A2.75 2.75 0 0 0 2.25 5v14A2.75 2.75 0 0 0 5 21.75h14A2.75 2.75 0 0 0 21.75 19V8c0-.2-.08-.39-.22-.53l-5-5a.75.75 0 0 0-.53-.22H7Zm.75 11.5v6.5h8.5v-6.5h-8.5Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Save.displayName = 'Save';