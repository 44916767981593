/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `TrashProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface TrashProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 21
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Trash'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 20
     */
    width?: number | string;
}

/**
 * The `Trash` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `TrashProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the TrashIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the TrashIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the TrashIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const TrashComponent = <Trash className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Trash = React.forwardRef<SVGSVGElement, TrashProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 21,
        testId = '',
        width = 20
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 20 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M6.8 2.02c.22-.22.52-.35.84-.35h3.81a1.2 1.2 0 0 1 1.2 1.2v1.19h-6.2v-1.2c0-.31.12-.62.34-.84ZM5 4.06v-1.2A2.63 2.63 0 0 1 7.64.24h3.81a2.62 2.62 0 0 1 2.63 2.62v1.2h4.05a.72.72 0 1 1 0 1.43h-1.19v12.65a2.62 2.62 0 0 1-2.62 2.62H4.77a2.62 2.62 0 0 1-2.62-2.62V5.49H.95a.72.72 0 1 1 0-1.43h4.06ZM3.58 5.49h11.93v12.65a1.2 1.2 0 0 1-1.2 1.19H4.78a1.2 1.2 0 0 1-1.19-1.2V5.5Zm4.06 3.34c.4 0 .71.32.71.72v5.72a.72.72 0 1 1-1.43 0V9.55c0-.4.32-.72.72-.72Zm4.53.72a.72.72 0 0 0-1.43 0v5.72a.72.72 0 0 0 1.43 0V9.55Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Trash.displayName = 'Trash';