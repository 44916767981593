import type {MouseEvent} from 'react';

import {spacing} from '@nfq/react-grid';
import styled from 'styled-components';

import {ButtonBase} from 'Client/ui/components/action/buttons/ButtonBase';

import {useIconButton} from './useIconButton';
import type {ButtonIcons} from 'Client/ui/assets/images/icons';
import type {Colors} from 'Client/ui/utils/globalStyles';

/**
 * Defines the base properties for a IconButton component. This interface supports conditional types based on the component type.
 * It provides a flexible way to define properties common to IconButtons, as well as properties specific to each.
 * This approach simplifies the creation of a component that can render a button based on the provided props.
 */
interface ComponentProps {
    /**
     * The icon to be displayed on the button.
     * Specify the icon name from the available options.
     */
    icon: ButtonIcons;
    /**
     * The icon color for the button.
     * Specify the color name from the available options.
     */
    iconColor?: Colors;
    /**
     * Determines whether the button is active.
     * When set to `true`, the button will be visually appear active.
     * This is useful for indicating the current state of the button.
     * For example, when the button is toggled on.
     * This is only applicable to buttons that can be toggled.
     *
     * @default false
     */
    isActive?: boolean;
    /**
     * Determines whether the button is disabled.
     * When set to `true`, the button will be non-interactive and visually appear disabled.
     */
    isDisabled?: boolean;
    /**
     * Event handler for the button's click event.
     * This function will be called when the button is clicked.
     *
     * @param event The mouse event object associated with the click event.
     */
    onClick?(event: MouseEvent<HTMLButtonElement>): void;
    /**
     * The size of the button.
     * Available options: `'large'` (default) or `'small'`.
     *
     * @default 'large'
     */
    size?: 'large' | 'small';
    /**
     * The style variant of the button.
     * This determines the visual style of the button.
     * Available options: `'dark'` (default) or `'light'`.
     *
     * @default 'dark'
     */
    style?: 'dark' | 'light';
    /**
     * The test id for the button.
     * This can be used for identifying the button in tests.
     */
    testId?: string;
    /**
     * The type of the button.
     * This determines the behavior of the button when clicked.
     * Available options: `'button'` (default), `'reset'`, or `'submit'`.
     *
     * @default 'button'
     */
    type?: 'button' | 'reset' | 'submit';
    /**
     * The variant of the button.
     * This determines the visual variant of the button.
     * Available options: `'primary'` (default) or `'secondary'`.
     *
     * @default 'primary'
     */
    variant?: 'primary' | 'secondary';
    /**
     * Custom width for special icons.
     */
    width?: number;
}

/**
 * The IconButton component is a customizable button component which has only an icon.
 * It can be styled using different variants and sizes.
 *
 * @param props            Component props.
 * @param props.icon       The icon of the IconButton.
 * @param props.iconColor  The icon color of the IconButton.
 * @param props.isActive   Whether the IconButton is active.
 * @param props.isDisabled Whether the IconButton is disabled.
 * @param props.size       The size of the IconButton.
 * @param props.style      The style of the IconButton.
 * @param props.testId     The test id.
 * @param props.type       The type of the IconButton.
 * @param props.onClick    The onClick function.
 * @param props.variant    The variant of the IconButton.
 * @param props.width      The width of the IconButton.
 * @returns The component.
 */
const IconButton = ({
    icon,
    iconColor,
    isActive = false,
    isDisabled = false,
    onClick = () => {},
    size = 'large',
    style = 'dark',
    testId = 'IconButton',
    type = 'button',
    variant = 'primary',
    width
}: ComponentProps) => {
    const {foregroundColor, Icon, iconSize} = useIconButton(style, size, icon, iconColor);

    return (
        <ButtonBase
            isActive={isActive}
            isDisabled={isDisabled}
            style={style}
            testId={testId}
            type={type}
            variant={variant}
            onClick={onClick}
        >
            <InnerWrapper $size={size}>
                <Icon color1={foregroundColor} height={iconSize} testId={`${testId}-icon`} width={width ?? iconSize} />
            </InnerWrapper>
        </ButtonBase>
    );
};

IconButton.displayName = 'IconButton';

export {IconButton};

interface InnerWrapperProps {
    $size: 'large' | 'small';
}

const InnerWrapper = styled.span<InnerWrapperProps>`
    align-items: center;
    display: flex;
    justify-content: center;
    padding: ${({$size, theme}) => ($size === 'large' ? spacing(3, theme) : spacing(1, theme))};
`;