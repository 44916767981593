/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `MousePointerProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface MousePointerProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 21
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'MousePointer'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 21
     */
    width?: number | string;
}

/**
 * The `MousePointer` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `MousePointerProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the MousePointerIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the MousePointerIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the MousePointerIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const MousePointerComponent = <MousePointer className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const MousePointer = React.forwardRef<SVGSVGElement, MousePointerProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 21,
        testId = 'MousePointer',
        width = 21
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 21 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="m9.08 19-.66.27a.72.72 0 0 0 1.34-.04L9.08 19ZM2 2l.27-.66a.71.71 0 0 0-.93.93L2 2Zm9.45 10.04.67.23-.67-.23Zm.59-.6.23.68-.23-.67ZM19 9.09l.23.68a.71.71 0 0 0 .04-1.34l-.27.66Zm-9.26 9.65-7.08-17-1.32.54 7.08 17 1.32-.54Zm1.03-6.92-2.36 6.96 1.35.46 2.36-6.96-1.35-.46Zm1.04-1.04c-.49.17-.87.55-1.04 1.04l1.35.46a.23.23 0 0 1 .15-.15l-.46-1.35Zm6.96-2.36-6.96 2.36.46 1.35 6.96-2.36-.46-1.35ZM1.73 2.66l17 7.08.54-1.32-17-7.08-.54 1.32Z" fill={color1} />
            <path d="m12 12 6 6" stroke={color1} strokeLinecap="round" strokeWidth="1.43" />
        </svg>
    );
});

MousePointer.displayName = 'MousePointer';