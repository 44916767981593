/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `TextProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface TextProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#192630'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 15
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Text'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 12
     */
    width?: number | string;
}

/**
 * The `Text` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `TextProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the TextIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the TextIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the TextIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const TextComponent = <Text className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Text = React.forwardRef<SVGSVGElement, TextProps>((props, ref) => {
    const {
        className = '',
        color1 = '#192630',
        height = 15,
        testId = 'Text',
        width = 12
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 12 15"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M7.29 15H5.23V2.15H.6V1.3c0-.48.27-.76.76-.76H11.9v1.6H7.29V15Z" fill={color1} />
        </svg>
    );
});

Text.displayName = 'Text';