/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `CameraProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface CameraProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Camera'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `Camera` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `CameraProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the CameraIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the CameraIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the CameraIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const CameraComponent = <Camera className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Camera = React.forwardRef<SVGSVGElement, CameraProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 24,
        testId = 'Camera',
        width = 24
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M9 2.25a.75.75 0 0 0-.62.33L6.6 5.25H3A2.75 2.75 0 0 0 .25 8v11A2.75 2.75 0 0 0 3 21.75h18A2.75 2.75 0 0 0 23.75 19V8A2.75 2.75 0 0 0 21 5.25h-3.6l-1.78-2.67a.75.75 0 0 0-.62-.33H9ZM7.62 6.42 9.4 3.75h5.2l1.78 2.67c.14.2.37.33.62.33h4A1.25 1.25 0 0 1 22.25 8v11A1.25 1.25 0 0 1 21 20.25H3A1.25 1.25 0 0 1 1.75 19V8A1.25 1.25 0 0 1 3 6.75h4c.25 0 .48-.13.62-.33ZM8.75 13a3.25 3.25 0 1 1 6.5 0 3.25 3.25 0 0 1-6.5 0ZM12 8.25a4.75 4.75 0 1 0 0 9.5 4.75 4.75 0 0 0 0-9.5Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Camera.displayName = 'Camera';