/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `EyeProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface EyeProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Eye'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `Eye` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `EyeProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the EyeIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the EyeIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the EyeIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const EyeComponent = <Eye className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Eye = React.forwardRef<SVGSVGElement, EyeProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 24,
        testId = 'Eye',
        width = 24
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M1.85 12c.12.2.29.5.51.84.47.73 1.16 1.69 2.06 2.65 1.81 1.93 4.38 3.76 7.58 3.76s5.77-1.83 7.58-3.76A18.92 18.92 0 0 0 22.15 12c-.12-.2-.29-.5-.51-.84a18.92 18.92 0 0 0-2.06-2.65C17.77 6.58 15.2 4.75 12 4.75S6.23 6.58 4.42 8.51A18.92 18.92 0 0 0 1.85 12ZM23 12l.67-.34-.01-.03a6.77 6.77 0 0 0-.2-.34 20.43 20.43 0 0 0-2.8-3.8C18.73 5.42 15.8 3.25 12 3.25S5.27 5.42 3.33 7.49a20.43 20.43 0 0 0-2.99 4.14v.03H.32L1 12l-.67-.34a.8.8 0 0 0 0 .68L1 12l-.67.34.01.03a6.52 6.52 0 0 0 .2.34 20.43 20.43 0 0 0 2.8 3.8c1.93 2.07 4.86 4.24 8.66 4.24s6.73-2.17 8.67-4.24a20.43 20.43 0 0 0 2.99-4.14v-.03h.01L23 12Zm0 0 .67.34a.8.8 0 0 0 0-.68L23 12Z" fill={color1} fillRule="evenodd" />
            <path clipRule="evenodd" d="M12 9.75a2.25 2.25 0 1 0 0 4.5 2.25 2.25 0 0 0 0-4.5ZM8.25 12a3.75 3.75 0 1 1 7.5 0 3.75 3.75 0 0 1-7.5 0Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Eye.displayName = 'Eye';