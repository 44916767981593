/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `AssetDownProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface AssetDownProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 21
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'AssetDown'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 37
     */
    width?: number | string;
}

/**
 * The `AssetDown` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `AssetDownProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the AssetDownIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the AssetDownIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the AssetDownIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const AssetDownComponent = <AssetDown className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const AssetDown = React.forwardRef<SVGSVGElement, AssetDownProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 21,
        testId = 'AssetDown',
        width = 37
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 37 21"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path d="M10.5.95.95 5.73l9.55 4.77 9.55-4.77L10.5.95ZM.95 15.27l9.55 4.78 9.55-4.78M.95 10.5l9.55 4.77 9.55-4.77m5.25.95 4.77 4.78 4.77-4.78m-4.77-6.68v11.46" fill="transparent" stroke={color1} strokeLinecap="round" strokeLinejoin="round" strokeWidth="1.43" />
        </svg>
    );
});

AssetDown.displayName = 'AssetDown';