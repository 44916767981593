/* eslint-disable @nfq/no-magic-numbers, max-len */
import React from 'react';

/**
 * The `ArchiveProps` interface defines the shape of the properties object that is expected for this component.
 * It outlines the required properties that needs to be provided when utilizing this component expecting an object of this type.
 */
interface ArchiveProps {
    /**
     * A string representing the CSS class to be applied to the LocationIconIcon element to override its default styling.
     *
     * @default ''
     */
    className?: string;
    /**
     * A string representing the color to be applied.
     *
     * @default '#0019A5'
     */
    color1?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    height?: number | string;
    /**
     * The `testId` property represents a unique identifier, usually in the form of a string, assigned to a component for testing purposes.
     * This property is crucial for uniquely identifying components during testing, allowing for more accurate and reliable tests.
     *
     * @default 'Archive'
     */
    testId?: string;
    /**
     * A number or string representing the height of the LocationIconIcon element.
     *
     * @default 24
     */
    width?: number | string;
}

/**
 * The `Archive` component is a functional component designed to render a customizable SVG element representing an Icon.
 * It receives several props through the `ArchiveProps` interface, allowing for customization of class, color, dimensions, and testing identifier.
 *
 * @param props           The component props.
 * @param props.className A string representing the CSS class to be applied to the ArchiveIcon element to override its default styling.
 * @param props.color1    A string representing the color to be applied to the Icon.
 * @param props.height    A number or string representing the height of the ArchiveIcon element.
 * @param props.testId    A string representing a unique identifier assigned to the component for testing purposes.
 * @param props.width     A number or string representing the height of the ArchiveIcon element.
 * @returns A React element representing the `Check` component with the specified properties.
 *
 * @example
 * ```tsx
 * const ArchiveComponent = <Archive className="myClass" color1="#000" height="20" testId="myTestId" width="20" />;
 * ```
 */
export const Archive = React.forwardRef<SVGSVGElement, ArchiveProps>((props, ref) => {
    const {
        className = '',
        color1 = '#0019A5',
        height = 24,
        testId = 'Archive',
        width = 24
    } = props;

    return (
        <svg
            ref={ref}
            className={className}
            data-cy={testId}
            height={height}
            viewBox="0 0 24 24"
            width={width}
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <path clipRule="evenodd" d="M3 7.25c.41 0 .75.34.75.75v12.25h16.5V8a.75.75 0 0 1 1.5 0v13c0 .41-.34.75-.75.75H3a.75.75 0 0 1-.75-.75V8c0-.41.34-.75.75-.75Z" fill={color1} fillRule="evenodd" />
            <path clipRule="evenodd" d="M.25 3c0-.41.34-.75.75-.75h22c.41 0 .75.34.75.75v5c0 .41-.34.75-.75.75H1A.75.75 0 0 1 .25 8V3Zm1.5.75v3.5h20.5v-3.5H1.75ZM9.25 12c0-.41.34-.75.75-.75h4a.75.75 0 0 1 0 1.5h-4a.75.75 0 0 1-.75-.75Z" fill={color1} fillRule="evenodd" />
        </svg>
    );
});

Archive.displayName = 'Archive';